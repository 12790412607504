<template>
    <div class="container">
        <div class="section-service-design">
            <p class="title1-sv-design">{{ $t('serviceDesign.title1') }}</p>
            <p class="title2-sv-design">{{ $t('serviceDesign.title2') }}</p>
        </div>
    </div>
    <img v-lazy="{src: require('@/assets/Home3D.webp')}" alt="" class="img-service-design">
    <div class="detail-section">
        <p>{{ $t('serviceDesign.detialServiceDesign')}}</p>
    </div>
    <div class="container">
        <div class="first-success">
            <p class="title1-first-success">{{ $t('serviceDesign.detail1') }}</p>
            <p class="title1-first-success">{{ $t('serviceDesign.detail2') }}</p>
            <p class="ex-plan">{{ $t('serviceDesign.example') }}</p>
            <p class="detail-plan">{{ $t('serviceDesign.ex1') }}<br>{{ $t('serviceDesign.ex2') }}<br>{{ $t('serviceDesign.ex3') }}<br>{{ $t('serviceDesign.ex4') }}<br>{{ $t('serviceDesign.ex5') }}<br>{{ $t('serviceDesign.ex6') }}<br>{{ $t('serviceDesign.ex7') }}<br>{{ $t('serviceDesign.ex8') }}<br>{{ $t('serviceDesign.ex9') }}<br>{{ $t('serviceDesign.ex10') }}</p>
            <p class="title1-first-success">{{ $t('serviceDesign.detail3') }}</p>
            <p class="title1-first-success">{{ $t('serviceDesign.detail4') }}</p>
            <p class="title1-first-success">{{ $t('serviceDesign.detail5') }}</p>
            <p class="title1-first-success">{{ $t('serviceDesign.detail6') }}</p>
            <p class="title1-first-success">{{ $t('serviceDesign.detail7') }}</p>

        </div>
    </div>
    <div class="team">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <img v-lazy="{src: require('@/assets/teamwork.webp')}" alt="" class="img-service-team">
                </div>
                <div class="col-sm-6">
                    <p class="title1 title-border title-team">{{ $t('serviceDesign.titleTeam')}}</p>
                    <p class="detailtitle-team">{{ $t('serviceDesign.detailTeam')}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <p class="title-service">{{ $t('serviceDesign.oneService')}}</p>
        <p class="title-service2">{{ $t('serviceDesign.startprice')}}</p>
        <div class="row">
            <div class="col-sm-6">
                <p class="price-service">{{ $t('serviceDesign.priceTitle')}}</p>
                <p class="font-size-price">{{ $t('serviceDesign.price')}}</p>
                <div class="buy">
                    <div class="row">
                        <div class="col-sm-5">
                            <p type="button" class="promotion-cl" data-toggle="modal" data-target="#exampleModalCenter" style="cursor: pointer;">{{ $t('serviceDesign.promotion')}}</p>
                            <!-- <p class="promotion-cl">{{ $t('serviceDesign.promotion')}}</p> -->
                        </div>
                        <div class="col-sm-5"><a href="https://blog.assetup.co.th/product/service-design/" class="router-home"><p class="promotion-cl">{{ $t('serviceDesign.buy')}}</p></a></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <p class="price-standard">{{ $t('serviceDesign.receive')}}</p>
                <p class="font-size-price">{{ $t('serviceDesign.standard1')}}</p>
                <p class="font-size-price">{{ $t('serviceDesign.standard2')}}</p>
                <p class="font-size-price">{{ $t('serviceDesign.standard3')}}</p>
                <p class="font-size-price">{{ $t('serviceDesign.standard4')}}</p>
                <!-- <p class="font-size-price">{{ $t('serviceDesign.standard5')}}</p> -->
                <p class="font-size-price">{{ $t('serviceDesign.standard6')}}</p>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <p class="modal-title" id="exampleModalLongTitle">{{ $t('serviceDesign.titleModal')}}</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{ $t('serviceDesign.detailModal')}}</p>
                <div class="form-control wizard-form-control d-flex align-items-center testing-code px-20px mb-10px">
                    <span class="code-promo">Promotion Code : </span>
                    <span class="code text-red">{{ testingCode }}</span>
                    <span class="btn-copy text-white copy-btn ml-auto" @click.stop.prevent="copyTestingCode">Copy</span>
                    <input type="hidden" id="testing-code" :value="testingCode">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('serviceDesign.close')}}</button>
                <button type="button" class="btn btn-secondary"><a href="https://blog.assetup.co.th/product/service-design/" class="gotoshop">{{ $t('serviceDesign.gotoshop')}}</a></button>
            </div>
            </div>
        </div>
    </div>
    <div class="container" style="background-color: #f2f2f2; margin-top: 3%;">
        <div class="ss-price-table" style="padding: 5%;">
            <p class="ss-headtable">{{ $t('serviceDesign.headtable')}}</p>
            <p class="ss-headtable2"><strong>{{ $t('serviceDesign.krong')}}</strong></p>
            <table style="margin-top: 2%;">
                <tr>
                    <th>{{ $t('serviceDesign.lang')}}</th>
                    <th>{{ $t('serviceDesign.priceplan')}}</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>100%</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>50%</td>
                </tr>
                <tr>
                    <td>3-5</td>
                    <td>25%</td>
                </tr>
                <tr>
                    <td>6-10</td>
                    <td>20%</td>
                </tr>
                <tr>
                    <td>11 ขึ้นไป</td>
                    <td>15%</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="container">
        <div class="special-service">
            <p class="title-border title-service-serm">{{ $t('serviceDesign.serviceSerm')}}<router-link to="/ServiceHomeSuccess" class="router-design">{{ $t('serviceDesign.planspecial')}}</router-link>{{ $t('serviceDesign.serviceSerm2')}} <router-link to="/ServiceHomeSuccess" class="router-design">{{ $t('serviceDesign.plan')}}</router-link> {{ $t('serviceDesign.serviceSerm3')}}</p>
            <p class="price-special">{{ $t('serviceDesign.special')}}</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            testingCode: "designpromo100",
        } 
    },
    methods: {
        copyTestingCode () {
          let testingCodeToCopy = document.querySelector('#testing-code')
          testingCodeToCopy.setAttribute('type', 'text')
          testingCodeToCopy.select()

          try {
            var successful = document.execCommand('copy');
            var msg = successful ? this.$t('serviceDesign.copysuccess') : 'unsuccessful';
            alert(msg);
          } catch (err) {
            alert('Oops, unable to copy');
          }

          /* unselect the range */
          testingCodeToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
        },
    },
}
</script>
<style>
.router-design{
    color: black;
 }
 .router-design:hover{
    color: black;
    text-decoration: none;
 }
 [v-cloak]{
  display: none;
}
.testing-code{
    height: 40px;
}
.code-promo {
    line-height: 18px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: gray;
    margin-right: 6px;
}
.code {
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}
.btn-copy {
    width: 88px;
    height: 25px;
    line-height: 26px;
    border-radius: 15px;
    margin-left: 10%;
    background-color: #d5b147;
    color: black;
    padding: 5px 10px;
    cursor: pointer;
}
.gotoshop{
    color: black;
    text-decoration: none;
}
.gotoshop:hover{
    color: white;
    text-decoration: none;
}
</style>